import React, { useEffect, useState } from 'react';
import AddNewAdmin from 'src/components/Admin/AddNewAdmin';
import westImg from 'src/assets/img/westImg.png';
import HeaderContentBlock from 'src/components/Common/HeaderContentBlock';
import logImg from 'src/assets/img/logImg.png';
import upDirectionAerrow from 'src/assets/svgs/upDirectionAerrow.svg';
import downDirectionAerrow from 'src/assets/svgs/downDirectionAerrow.svg';
import bluePlusCircleIcon from 'src/assets/svgsResponsive/bluePlusCircleIcon.svg';
import searchIcon from 'src/assets/svgs/searchIcon.svg';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { adminList } from 'src/services/admin';
import { makeHoursAndMinutes } from 'src/utils/function';
import { BsStar, BsTrophy } from 'react-icons/bs';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';
import ResponsiveAdminListAccordion from 'src/components/Responsive/admin/ResponsiveAdminListAccordion';
import blueCancelIcon from 'src/assets/svgsResponsive/blueCancelIcon.svg';

const Admin = () => {
    const [addNewAdmin, setAddNewAdmin] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(50);
    const [first, setFirst] = useState(0);
    const [adminListData, setAdminListData] = useState([]);
    const [totalAdminRecords, setTotalAdminRecords] = useState(0);
    const [filterSelectData, setFilterSelectData] = useState({ value: 'yesterday', label: 'Yesterday' });
    const [openAcoordionResponsive, setOpenAcoordionResponsive] = useState<any>(null);
    const controller = new AbortController();
    const filterOptions = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'this_week', label: 'This week' },
        { value: 'this_month', label: 'This month' },
        { value: 'this_year', label: 'This year' },
        { value: 'last_7_days', label: 'Last 7 days' },
        { value: 'last_30_days', label: 'Last 30 days' },
        { value: 'all_time', label: 'All time' },
    ];
    const filterSelectFunction = (data: any) => {
        setFilterSelectData(data);
    };

    useEffect(() => {
        if (searchInput) {
            getAdminList(`?page=${page}&limit=${rows}&search=${searchInput}&dataRange=${filterSelectData.value}&orderByColumnName=adminId&ascOrDesc=desc`, controller.signal);
        } else {
            getAdminList(`?page=${page}&limit=${rows}&dataRange=${filterSelectData.value}&orderByColumnName=adminId&ascOrDesc=desc`, controller.signal);
        }
        return () => controller.abort();
    }, [page, rows, searchInput, filterSelectData]);

    const getAdminList = async (url: string, signal?: any) => {
        const adminResData = await adminList(url, signal);
        setAdminListData(adminResData?.data?.data);
        setTotalAdminRecords(adminResData?.data?.total);
    };

    const handleSearch = (e: any) => {
        setPage(0);
        setSearchInput(e.target.value);
    };
    const newAdminSuccess = () => {
        if (page === 0) {
            getAdminList(`?page=${page}&limit=${rows}&dataRange=${filterSelectData.value}&orderByColumnName=adminId&ascOrDesc=desc`, controller.signal);
        } else {
            setPage(0);
            setFirst(0);
        }
        setAddNewAdmin(false);
        setBtnDisabled(false);
    };
    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.page);
    };
    const GetName = (row: any) => <div>{row?.adminFullName}</div>;
    const GetSpentTime = (row: any) => <div>{makeHoursAndMinutes(Number(row?.total_screening_time_in_min))}</div>;
    const GetJobCreated = (row: any) => <div>{row?.total_jobs_created}</div>;
    const GetJobCompleted = (row: any) => <div>{row?.total_jobs_completed}</div>;
    const GetJobCancelled = (row: any) => <div>{row?.total_jobs_cancelled}</div>;
    const GetRegisterdWorker = (row: any) => <div>{row?.total_employees_created}</div>;
    return (
        <div>
            <div className="lg:hidden flex flex-col md:flex-row gap-3 items-center">
                <div className="w-full">
                    <div className="relative w-full">
                        <input
                            className={`${
                                addNewAdmin ? 'opacity-40' : ''
                            } w-full h-[41px] px-5 py-2 pr-10 bg-white focus-visible:outline-none focus:border focus:border-primary rounded-3xl border border-gray10 placeholder:text-gray20`}
                            placeholder="Search"
                            disabled={addNewAdmin ? true : false}
                            onChange={handleSearch}
                            value={searchInput}
                        />

                        <img className="w-6 h-6 absolute right-3 top-2" src={searchIcon} alt="searchicon" />
                    </div>
                </div>
                <div className="w-full flex flex-row items-center">
                    <div className="dashboard_dropdown w-full !h-[44px] !rounded-lg flex items-center">
                        <SelectBox
                            value={filterSelectData}
                            onChangeFunc={filterSelectFunction}
                            options={filterOptions}
                            isDisabled={addNewAdmin ? true : false}
                            classComp="custom_react_select"
                            menuPlacement="bottom"
                        />
                    </div>
                    {addNewAdmin ? (
                        <span className="ml-2" onClick={() => setAddNewAdmin(false)}>
                            <img src={blueCancelIcon} alt="blueCancelIcon" className="cursor-pointer min-w-[38px] h-[44px] md:min-w-[50px] md:h-[50px] " />
                        </span>
                    ) : (
                        <span className="ml-2" onClick={() => setAddNewAdmin(true)}>
                            <img src={bluePlusCircleIcon} alt="bluePlusCircleIcon" className="cursor-pointer min-w-[38px] h-[44px] md:min-w-[50px] md:h-[50px] " />
                        </span>
                    )}
                </div>
            </div>
            <HeaderContentBlock
                closeOnClick={() => setAddNewAdmin(false)}
                saveOnClick={() => setIsSave(true)}
                addNewOnClick={() => setAddNewAdmin(true)}
                nextStep={addNewAdmin}
                addNewBtnText={'Add new Admin'}
                isEmploy={true}
                isAdmin={true}
                headerText="Admins"
                isDisabled={btnDisabled}
                searchOnchange={handleSearch}
                searchInput={searchInput}
                setFilterSelectData={setFilterSelectData}
                filterSelectData={filterSelectData}
            />
            {addNewAdmin && (
                <AddNewAdmin
                    setIsSave={setIsSave}
                    isSave={isSave}
                    isOnSuccess={() => {
                        newAdminSuccess();
                    }}
                    setBtnDisabled={setBtnDisabled}
                    btnDisabled={btnDisabled}
                />
            )}
            <div className={`pt-5 pb-0 w-full ${addNewAdmin ? 'hidden' : 'max-lg:block hidden'}`}>
                <>
                    {adminListData?.length === 0 && (
                        <div
                            className=" bg-white max-md:h-10 flex justify-center items-center max-lg:font-semibold
                         max-lg:text-xl max-lg:h-12  max-md:font-medium max-md:text-lg"
                        >
                            No Admin found.
                        </div>
                    )}
                    <ResponsiveAdminListAccordion adminListData={adminListData} openAcoordionResponsive={openAcoordionResponsive} setOpenAcoordionResponsive={setOpenAcoordionResponsive} />
                    <div className="mt-5">
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={totalAdminRecords}
                            onPageChange={onPageChange}
                            className="datatable_pagination"
                            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        />
                    </div>
                </>
            </div>
            <div className="py-6 hidden lg:block">
                <DataTable value={adminListData} tableClassName="logdatatable admintable" emptyMessage="No admin found." className="">
                    <Column field="adminFullName" header="Name" body={GetName}></Column>
                    <Column field="total_screening_time_in_min" header="Spent Time" body={GetSpentTime}></Column>
                    <Column field="total_jobs_created" header="Jobs Created" body={GetJobCreated}></Column>
                    <Column field="total_jobs_completed" header="Jobs Completed" body={GetJobCompleted}></Column>
                    <Column field="total_jobs_cancelled" header="Jobs Cancelled" body={GetJobCancelled}></Column>
                    <Column field="total_employees_created" header="Workers Registered" body={GetRegisterdWorker}></Column>
                </DataTable>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalAdminRecords}
                    onPageChange={onPageChange}
                    className="datatable_pagination"
                    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                />
            </div>
        </div>
    );
};

export default Admin;
